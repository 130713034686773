import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_HOME,
    GET_ALL_PRODUCTS,
    GET_ALL_BID_HISTORY,
    GET_WATCHLIST_PROD,
    GET_EXPIRED,
    GET_WON,
    GET_WINNING,
    GET_OUTBID,
    GET_SAVED_SEARCH_BYID,
    GET_SAVED_SEARCH,
    GET_INVOICE_DETAIL,
    WATCH_COUNT,
    GET_SIMILAR,
    GET_PRODUCT_INFO,
    GET_DASHBOARD,
    GET_LOST,
} from './productTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_INVOICE_DETAIL:
            return {
                ...state,
                invoice_detail: action.payload,
            }
        case WATCH_COUNT:
            return {
                ...state,
                watch_count: action.payload,
            }
        case GET_SAVED_SEARCH:
            return {
                ...state,
                saved_searches: action.payload,
            }
        case GET_SAVED_SEARCH_BYID: {
            return {
                ...state,
                single_saved_search: action.payload,
            }
        }
        case GET_ALL_BID_HISTORY:
            return {
                ...state,
                search_allbidhistory: action.payload,
            }
        case GET_PRODUCT_INFO:
            return {
                ...state,
                product_info: action.payload,
            }
        case GET_SIMILAR:
            return {
                ...state,
                similar_products: action.payload,
            }
        case GET_EXPIRED:
            return {
                ...state,
                expired_data: action.payload,
            }
        case GET_DASHBOARD:
            return {
                ...state,
                dashboard_data: action.payload,
            }
        case GET_WINNING:
            return {
                ...state,
                winning_data: action.payload,
            }
        case GET_LOST:
            return {
                ...state,
                lost_data: action.payload,
            }
        case GET_OUTBID:
            return {
                ...state,
                outbid_data: action.payload,
            }
        case GET_WON:
            return {
                ...state,
                won_data: action.payload,
            }
        case GET_WATCHLIST_PROD:
            return {
                ...state,
                watch_data: action.payload,
            }
        case GET_ALL_HOME:
            return {
                ...state,
                search_allHomeproducts: action.payload,
            }
        case GET_ALL_PRODUCTS:
            return {
                ...state,
                search_allproducts: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
