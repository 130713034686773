import React, { useState, useContext, useEffect, useRef } from 'react'
// import openSocket from 'socket.io-client'
import './Dashboard.css'
import { makeStyles } from '@material-ui/core/styles'
import { Link, NavLink } from 'react-router-dom'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import DashboardLayout from './DashboardLayout'
import Loaders from '@/custom/components/molecules/Loaders'
import { ListItem } from '@material-ui/core'
import ItemView from './MyAuctions/Mybidscommon'
import Watchlist from './MyAuctions/Watchlist'
import SortbyCom from '../Search/SortbyComp'
import Commoncomp from './MyAuctions/SortbyComp'
import CustomCommonContext from '@/custom/context/common/commonContext'
import SEO from '@/utils/SEO'
import Won from './MyAuctions/Won'
import Lost from './MyAuctions/Lost'
import Winning from './MyAuctions/Winning'
import Outbid from './MyAuctions/Outbid'
import AllData from './MyAuctions/All'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const MyAuctions = (props) => {
    //Auction page

    const [auctionView, setAuctionView] = useState('Grid')
    const [seoTitle, setSeoTitle] = useState('')
    const [seoUrl, setSeoUrl] = useState('')
    const [locationFilter, setLocationFilter] = useState([])
    const { allLocations } = useContext(CustomCommonContext)

    const pageOptions = [
        {
            value: '10',
            show: '10 per page',
        },
        {
            value: '20',
            show: '20 per page',
        },
        {
            value: '30',
            show: '30 per page',
        },
    ]
    const pickupOptions = [
        {
            value: '',
            show: 'None',
        },
        {
            value: '1',
            show: 'Picked Up',
        },
        {
            value: '0',
            show: 'Not Picked Up',
        },
        {
            value: '2',
            show: 'Returned',
        },
        {
            value: '3',
            show: 'Forfeited',
        },
    ]

    const sortShow = [
        {
            value: 'p.id, desc',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 4,
            show: 'Current price high to low',
        },

        // {
        //     value: 1,
        //     show: 'Ending today',
        // },
        // {
        //     value: `CASE WHEN p.bprice > 0 THEN 0 ELSE 1 END ASC, CASE WHEN p.rprice - p.wprice >= 0 THEN 0 ELSE 1 END ASC, p.bprice * p.qty asc`,
        //     show: 'Buy now price low to high',
        // },
        // {
        //     value: `CASE WHEN p.bprice > 0 THEN 0 ELSE 1 END ASC, CASE WHEN p.rprice - p.wprice >= 0 THEN 0 ELSE 1 END ASC, p.bprice * p.qty desc`,
        //     show: 'Buy now price high to low',
        // },
    ]

    if (props.match.params.type !== 'lost' && props.match.params.type !== 'won') {
        sortShow.push({
            value: 1,
            show: 'Time remaining',
        })
    }

    const formikSearch = useFormik({
        initialValues: {
            limit: 10,
            page: 1,
            title: '',
            auctionView: 'Grid',
            location: '',
            action: props.match.params.type,
        },
    })
    //update auctionview(Grid or list)
    const updateView = (data) => {
        setAuctionView(data)
        formikSearch.setFieldValue('auctionView', data)
    }

    useEffect(() => {
        if (props.match.params.type == 'watchlist') {
            formikSearch.setFieldValue(`typearr`, [1, 2, 3, 4])
        }
        formikSearch.setFieldValue(`page`, 1)
        formikSearch.handleReset()
    }, [props.match.params.type])

    const formik = useFormik({
        initialValues: {
            searchbar: '',
        },
    })

    useEffect(() => {
        if (allLocations && allLocations.length) {
            let location = allLocations.map((data) => {
                return {
                    show: `${data.city_state_display}`,
                    value: `${data.city_state_display}`,
                }
            })
            location.unshift({
                show: `None`,
                value: ``,
            })
            setLocationFilter(location)
        }
    }, [allLocations])

    const locationOptions = locationFilter

    const clearSearchFilter = (action) => {
        formikSearch.values = {
            ...formikSearch.values,
            limit: 10,
            page: 1,
            orderby: 'p.date_closed,asc',
            order: '',
            filters: {
                category: {
                    ...formikSearch.filters.category,
                    value: [],
                },
                condition: {
                    ...formikSearch.filters.condition,
                    value: [],
                },
                location: {
                    ...formikSearch.filters.location,
                    value: [],
                },
                price: {
                    ...formikSearch.filters.price,
                    value: '',
                },
                searchbar: {
                    ...formikSearch.filters.searchbar,
                    value: '',
                },
                auctionid: {
                    ...formikSearch.filters.auctionid,
                    value: [],
                },
            },
        }
        formik.values.searchbar = ''
    }

    const renderHeading = () => {
        switch (props.match.params.type) {
            case 'all':
                return 'All Items'
            case 'auction':
                return 'My Bids'
            case 'watchlist':
                return 'My Watchlist'
            case 'winning':
                return 'Winning Items'
            case 'outbid':
                return 'Losing Items'
            case 'won':
                return 'Won Items'
            case 'lost':
                return 'Lost Items'
            case 'buynow':
                return 'Buy Now'
            default:
                return ''
        }
    }

    const renderSeo = () => {
        switch (props.match.params.type) {
            case 'watchlist':
                return setSeoTitle('My Watchlist')
            case 'buynow':
                return setSeoTitle('My Purchase')
            default:
                return setSeoTitle('My Bids')
        }
    }

    useEffect(() => {
        renderSeo()
    }, [props.match.params.type])

    return (
        <>
            <SEO title={seoTitle} url={seoUrl} />
            <DashboardLayout title={renderHeading()}>
                <div className="dashboardInner myAuctions">
                    <Commoncomp
                        renderHeading={renderHeading}
                        formikSearch={formikSearch}
                        sortShow={sortShow}
                        pageOptions={pageOptions}
                        viewSwitch={false}
                        locationFilter={props.match.params.type == 'won' ? true : false}
                        pickupFilter={props.match.params.type == 'won' ? true : false}
                        locationOptions={locationOptions}
                        pickupOptions={pickupOptions}
                        setAuctionView={updateView}
                        resultPerPage={true}
                        sortBy={true}
                        path={true}
                    />
                    {props.location.pathname == '/auctions/all' ||
                    props.location.pathname == '/auctions/winning' ||
                    props.location.pathname == '/auctions/outbid' ||
                    props.location.pathname == '/auctions/won' ? (
                        <div className="auctionStatus">
                            <ListItem button>
                                <NavLink activeClassName="active" to="/auctions/all">
                                    All
                                </NavLink>
                            </ListItem>

                            <ListItem button>
                                <NavLink activeClassName="active" to="/auctions/winning">
                                    Winning
                                </NavLink>
                            </ListItem>

                            <ListItem button>
                                <NavLink activeClassName="active" to="/auctions/outbid">
                                    Losing
                                </NavLink>
                            </ListItem>

                            <ListItem button>
                                <NavLink activeClassName="active" to="/auctions/won">
                                    Won
                                </NavLink>
                            </ListItem>
                        </div>
                    ) : null}
                    <div className="auctionView">
                        {props.match.params.type == 'watchlist' ? (
                            <Watchlist
                                auctionView={auctionView}
                                formikSearch={formikSearch}
                                type={props.match.params.type}
                            />
                        ) : props.match.params.type == 'won' ? (
                            <Won
                                auctionView={auctionView}
                                formikSearch={formikSearch}
                                type={props.match.params.type}
                            />
                        ) : props.match.params.type == 'lost' ? (
                            <Lost
                                auctionView={auctionView}
                                formikSearch={formikSearch}
                                type={props.match.params.type}
                            />
                        ) : props.match.params.type == 'winning' ? (
                            <Winning
                                auctionView={auctionView}
                                formikSearch={formikSearch}
                                type={props.match.params.type}
                            />
                        ) : props.match.params.type == 'outbid' ? (
                            <Outbid
                                auctionView={auctionView}
                                formikSearch={formikSearch}
                                type={props.match.params.type}
                            />
                        ) : props.match.params.type == 'all' ? (
                            <AllData
                                auctionView={auctionView}
                                formikSearch={formikSearch}
                                type={props.match.params.type}
                            />
                        ) : (
                            <ItemView
                                auctionView={auctionView}
                                formikSearch={formikSearch}
                                type={props.match.params.type}
                            />
                        )}
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}

export default MyAuctions
