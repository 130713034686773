import React, { useState, useContext, useEffect, useRef } from 'react'
// import openSocket from 'socket.io-client'
import './Dashboard.css'
import ProductContext from '@/custom/context/product/productContext'
import AuthContext from '@/custom/context/auth/authContext'
import AlertContext from '@/custom/context/alert/alertContext'
import { makeStyles } from '@material-ui/core/styles'
import { Link, NavLink } from 'react-router-dom'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import DashboardLayout from './DashboardLayout'
import Loaders from '@/custom/components/molecules/Loaders'
import { ListItem } from '@material-ui/core'
import ItemView from './MyAuctions/Expired'
import SortbyCom from '../Search/SortbyComp'
import Commoncomp from './MyAuctions/SortbyComp'

import SEO from '@/utils/SEO'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const ExpiredAuction = (props) => {
    //Auction page

    const [auctionView, setAuctionView] = useState('Grid')
    const [seoTitle, setSeoTitle] = useState('')
    const [seoUrl, setSeoUrl] = useState('')

    const pageOptions = [
        {
            value: '10',
            show: '10 per page',
        },
        {
            value: '20',
            show: '20 per page',
        },
        {
            value: '30',
            show: '30 per page',
        },
    ]

    const sortShow = [
        {
            value: 'p.id, desc',
            show: 'None',
        },
        {
            value: `CASE WHEN p.wprice > 0 THEN 0 ELSE 1 END ASC, p.wprice asc`,
            show: 'Current price low to high',
        },
        {
            value: `CASE WHEN p.wprice > 0 THEN 0 ELSE 1 END ASC, p.wprice desc`,
            show: 'Current price high to low',
        },
        // {
        //     value: `CASE WHEN p.bprice > 0 THEN 0 ELSE 1 END ASC, CASE WHEN p.rprice - p.wprice >= 0 THEN 0 ELSE 1 END ASC, p.bprice * p.qty asc`,
        //     show: 'Buy now price low to high',
        // },
        // {
        //     value: `CASE WHEN p.bprice > 0 THEN 0 ELSE 1 END ASC, CASE WHEN p.rprice - p.wprice >= 0 THEN 0 ELSE 1 END ASC, p.bprice * p.qty desc`,
        //     show: 'Buy now price high to low',
        // },
    ]

    const formikSearch = useFormik({
        initialValues: {
            limit: 12,
            page: 1,
            orderby: 'p.date_closed,asc',
            order: '',
            auctionView: 'Grid',

            filters: {
                market_status: {
                    value: ['sold', 'unsold', 'closed'],
                    type: 'array',
                    field: 'p.market_status',
                },
                category: {
                    value: [],
                    type: 'array',
                    field: 'it.categoryTypeId',
                },
                condition: {
                    value: [],
                    type: 'array',
                    field: 'it.conditionTypeId',
                },
                location: {
                    value: [],
                    type: 'array',
                    field: 'p.location_id',
                },
                price: {
                    value: '',
                    type: 'greaterequal',
                    field: 'p.wprice',
                },
                searchbar: {
                    value: '',
                    type: 'like',
                    field: 'p.title,p.desc_proc',
                },
                auctionid: {
                    value: [],
                    type: 'array',
                    field: 'p.auctionid',
                },
            },
            action: 'all',
        },
    })
    //update auctionview(Grid or list)
    const updateView = (data) => {
        setAuctionView(data)
        formikSearch.setFieldValue('auctionView', data)
    }

    const formik = useFormik({
        initialValues: {
            searchbar: '',
        },
    })

    const clearSearchFilter = (action) => {
        formikSearch.values = {
            ...formikSearch.values,
            limit: 10,
            page: 1,
            orderby: 'p.date_closed,asc',
            order: '',
            filters: {
                market_status: {
                    ...formikSearch.filters.market_status,
                    value: ['sold', 'unsold', 'closed'],
                },
                category: {
                    ...formikSearch.filters.category,
                    value: [],
                },
                condition: {
                    ...formikSearch.filters.condition,
                    value: [],
                },
                location: {
                    ...formikSearch.filters.location,
                    value: [],
                },
                price: {
                    ...formikSearch.filters.price,
                    value: '',
                },
                searchbar: {
                    ...formikSearch.filters.searchbar,
                    value: '',
                },
                auctionid: {
                    ...formikSearch.filters.auctionid,
                    value: [],
                },
            },
        }
        formik.values.searchbar = ''
    }

    console.log(props.match.params.type, 'sa')

    const renderHeading = () => {
        switch (props.match.params.type) {
            case 'all':
                return 'My Bids'
            case 'auction':
                return 'My Bids'
            case 'watchlist':
                return 'My Watchlist'
            case 'winning':
                return 'Winning Items'
            case 'outbid':
                return 'Losing Items'
            case 'won':
                return 'Won Items'
            case 'buynow':
                return 'Buy Now'
            default:
                return 'All'
        }
    }

    const renderSeo = () => {
        switch (props.match.params.type) {
            case 'watchlist':
                return setSeoTitle('My Watchlist')
            case 'buynow':
                return setSeoTitle('My Purchase')
            default:
                return setSeoTitle('My Bids')
        }
    }

    useEffect(() => {
        renderSeo()
    }, [props.match.params.type])

    return (
        <>
            <SEO title={seoTitle} url={seoUrl} />
            <DashboardLayout title={'Expired Auctions'}>
                <div className="dashboardInner myAuctions">
                    <Commoncomp
                        renderHeading={renderHeading}
                        formikSearch={formikSearch}
                        sortShow={sortShow}
                        pageOptions={pageOptions}
                        viewSwitch={false}
                        setAuctionView={updateView}
                        resultPerPage={true}
                        sortBy={true}
                        path={true}
                    />

                    <div className="auctionView">
                        <ItemView auctionView={auctionView} formikSearch={formikSearch} />
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}

export default ExpiredAuction
