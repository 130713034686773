import React, { useContext, useState, useEffect } from 'react'
import DashboardLayout from '../DashboardLayout'
import { Button } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import NoRecordsFound from '@/custom/components/atoms/NoRecordsFound'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import CustomDialog from '@/custom/components/organisms/Dialog'
import ProductContext from '@/custom/context/product/productContext'
import AlertContext from '@/custom/context/alert/alertContext'
import AuthContext from '@/custom/context/auth/authContext'
import * as Yup from 'yup'
import { mapData } from '@/custom/common/components'
import { useFormik } from 'formik'

import { dateTimeFormatFunction } from '@/custom/common/components'

import SEO from '@/utils/SEO'
let randomVariable = Math.random()
const SavedSearchBody = (props) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const [toggleDialog, setToggleDialog] = useState(false)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [currentID, setCurrentID] = useState(0)
    const [record, setRecord] = useState([])
    const [editDialog, setEditDialog] = useState(false)
    const [limit, setLimit] = useState(15)

    const { isAuthenticated } = authContext

    const {
        getSavedSearch,
        saved_searches,
        deleteSavedSearch,
        updateSavedSearch,
        getSavedSearchById,
        single_saved_search,
        responseStatus,
        clearResponse,
    } = productContext

    useEffect(() => {
        // console.log(saved_searches, 'from response status')
        if (saved_searches) {
            /*if (responseStatus.from === 'saved_search' && responseStatus.data) {*/
            //console.log(responseStatus.from, 'from is working')
            console.log(saved_searches.records, 'data is working')
            setRecord(
                saved_searches.records?.savedSearchList?.length
                    ? saved_searches.records.savedSearchList
                    : [],
            )
            setTotalPage(saved_searches.records.savedSearchCount)
            // setRecord(responseStatus.data.records)
            // setTotalPage(responseStatus.data.totalRecords)
            // }
        }
    }, [saved_searches])

    const loadTable = (page = currentPage) => {
        getSavedSearch({})
    }

    const changeEditDialogStatus = () => {
        setEditDialog(!editDialog)
        resetForm()
    }

    const removeRecord = (id) => {
        deleteSavedSearch({ saved_search_id: id })
        loadTable()
        setToggleDialog(false)
    }

    useEffect(() => {
        if (isAuthenticated) {
            loadTable()
        }
    }, [isAuthenticated])

    const onHandlePage = async (event, value) => {
        setCurrentPage(value)
        loadTable(value)
    }

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const validationArray = Yup.object({
        lot_title: Yup.string().required('Title is required feild'),
    })

    const formikSelection = useFormik({
        initialValues: {
            lot_title: '',
            category_id: '',
            saved_search_name: '',
            saved_search_id: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            updateSavedSearch(values)
            resetForm()
            setEditDialog(false)
        },
    })

    const updateId = (data) => {
        formikSelection.handleReset()
        randomVariable = Math.random()
        getSavedSearchById(
            {
                saved_search_id: data.id,
            },
            randomVariable,
        )
        // formikSelection.values.id = data.id
        // formikSelection.values.search_text = data.search_text
        // formikSelection.values.category = data.category ? data.category.split(',') : []
    }

    useEffect(() => {
        if (single_saved_search) {
            if (single_saved_search?.records?.savedSearchInfo.length) {
                let singleData = single_saved_search?.records?.savedSearchInfo[0]
                formikSelection.setFieldValue('saved_search_id', singleData.id)
                formikSelection.setFieldValue('lot_title', singleData.title)
                // formikSelection.values.saved_search_id = singleData.id
                // formikSelection.values.lot_title = singleData.searchtext
                formikSelection.values.saved_search_name = singleData.searchtext
                console.log(singleData, 'single_saved_search')
            }
        }
    }, [single_saved_search])

    useEffect(() => {
        console.log(responseStatus, 'responseStatus')
        if (responseStatus && responseStatus.from == 'updateSavedSearch') {
            if (responseStatus.status == 'success') {
                setEditDialog(false)
                getSavedSearch({})
            }
            setAlert(responseStatus.message, responseStatus.status)
        }
        if (responseStatus && responseStatus.from == 'deleteSavedSearch') {
            setToggleDialog(false)
            setAlert(responseStatus.message, responseStatus.status)
        }

        clearResponse()
    }, [responseStatus])

    const resetForm = () => {
        formikSelection.handleReset()
    }

    const searchBody = {
        formik: formikSelection,
        data: [
            {
                label: 'Title',
                type: 'text',
                placeholder: 'Enter the title',
                class: 'col-12',
                name: 'lot_title',
            },
            // {
            //     label: 'Select Category',
            //     type: 'multiselect',
            //     placeholder: 'Select Category',
            //     class: 'col-12',
            //     options: category,
            //     name: 'category',
            // },
        ],
    }

    const closeFunction = () => {
        setEditDialog(false)
        resetForm()
    }

    // useEffect(() => {
    //     if (
    //         responseStatus &&
    //         responseStatus.status == 'success' &&
    //         responseStatus.from == 'saved_search_msg'
    //     ) {
    //         getSavedSearch({ page: 1, limit: limit })
    //     }
    // }, [responseStatus])

    return (
        <>
            <SEO title="My Saved Searches" url={window.location.href} />
            <DashboardLayout title={'My saved searches'}>
                <div className="dashboardInner mySavedSearches">
                    {record && record?.length ? (
                        <>
                            <h5 className="dashSubtitle">
                                {'You can find all your saved searches here'}
                                <br />
                                {'Saved search helps you find your preferred products easily'}
                            </h5>
                            <div className="table-responsive dpTable">
                                <table className="table text-left">
                                    <thead>
                                        <tr>
                                            <th scope="col">{'S no'}</th>
                                            <th scope="col">{'Title'}</th>
                                            <th scope="col">{'Date added'}</th>
                                            <th scope="col">{'Action'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {record.map((data, index) => (
                                            <tr key={`saved_${index}`}>
                                                <td>{index + 1}</td>
                                                <td>{data.title}</td>
                                                <td>{dateTimeFormatFunction(data.date_added)}</td>
                                                <td>
                                                    <div className="d-flex justify-content-center ssActBtn">
                                                        <Button
                                                            id="open_in_new"
                                                            onClick={() => {
                                                                updateId(data)
                                                                setEditDialog(true)
                                                            }}
                                                        >
                                                            <span className="material-icons">
                                                                edit
                                                            </span>
                                                        </Button>
                                                        <Button
                                                            id="savedSearch_delete"
                                                            onClick={() => {
                                                                setCurrentID(data.id)
                                                                setToggleDialog(true)
                                                            }}
                                                        >
                                                            <span className="material-icons outlined">
                                                                delete
                                                            </span>
                                                        </Button>
                                                        <PrimaryButton
                                                            id="open_in_new"
                                                            onClick={() =>
                                                                props.getSearchBarObjet(data.title)
                                                            }
                                                        >
                                                            <span className="material-icons">
                                                                open_in_new
                                                            </span>
                                                        </PrimaryButton>
                                                        {/* <Button
                                                            id="open_in_new"
                                                            onClick={() =>
                                                                props.getSearchBarObjet(data.title)
                                                            }
                                                        >
                                                            <span className="material-icons">
                                                                open_in_new
                                                            </span>
                                                        </Button> */}
                                                        {/* <Button
                                                            id="savedSearch_delete"
                                                            onClick={() => {
                                                                setCurrentID(data.id)
                                                                setToggleDialog(true)
                                                            }}
                                                        >
                                                            <span className="material-icons">
                                                                delete
                                                            </span>
                                                        </Button> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Pagination
                                    count={Math.ceil(totalPage / limit)}
                                    page={currentPage}
                                    onChange={onHandlePage}
                                    siblingCount={3}
                                    showFirstButton
                                    showLastButton
                                    boundaryCount={2}
                                />
                            </div>
                        </>
                    ) : (
                        <NoRecordsFound />
                    )}
                </div>

                <CustomDialog
                    title={'Confirm delete'}
                    open={toggleDialog}
                    function={changeDialogStatus}
                >
                    <h5>{'You are about to delete this saved search'}</h5>
                    <h5>{'Please confirm if you want to continue with this action'}</h5>
                    <div className="actionWrapper">
                        <Button id="no_cancel" onClick={() => setToggleDialog(false)}>
                            {'No cancel'}
                        </Button>
                        <PrimaryButton
                            id="yes_delete"
                            type="button"
                            onClick={() => removeRecord(currentID)}
                            label={'Yes delete'}
                        />
                    </div>
                </CustomDialog>

                <CustomDialog
                    title={`${formikSelection.id ? 'Edit saved search' : 'Add saved search'}`}
                    open={editDialog}
                    function={changeEditDialogStatus}
                    disableBackdropClick
                >
                    <div className="row">{mapData(searchBody)}</div>
                    <div className="actionWrapper">
                        <Button id="no_cancel" onClick={() => closeFunction()}>
                            {'Cancel'}
                        </Button>
                        <PrimaryButton
                            id="category_submit"
                            type="button"
                            onClick={() => formikSelection.handleSubmit()}
                            label={'Submit'}
                        />
                    </div>
                </CustomDialog>
            </DashboardLayout>
        </>
    )
}

export default SavedSearchBody
