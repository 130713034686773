import React, { useContext, useState, useEffect } from 'react'
import { Divider, ListItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { NavLink } from 'react-router-dom'
import './Dashboard.css'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { handleRedirectInternal } from '@/custom/common/components'
import AuthContext from '@/custom/context/auth/authContext'
import CustomCommonContext from '@/custom/context/common/commonContext'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}))

const SideNav = () => {
    const classes = useStyles()
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const {
        allLocations,
        allCategory,
        searchValue,
        setSearchValue,
        noPendingInvoices,
        setPendingInvoices,
    } = useContext(CustomCommonContext)

    const { user } = authContext

    const [expanded, setExpanded] = useState('')

    const handleChange = (panel) => {
        if (expanded === panel) {
            setExpanded('')
        } else {
            setExpanded(panel)
        }
    }

    useEffect(() => {
        if (location.pathname.split('/')[1]) {
            setExpanded(location.pathname.split('/')[1])
        }
    }, [location.pathname])

    return (
        <div className="sideNav">
            {/* <div className="userAvatarProfile">
                <span className="userInitials large">
                    {user?.first_name?.charAt(0)}
                    {user?.last_name?.charAt(0)}{' '}
                </span>
                <h4>
                    <small className="d-block">Hello</small>
                    {user?.first_name !== 'null' ? user?.first_name : user?.companyname}
                </h4>
            </div> */}
            <ul>
                {!noPendingInvoices ? (
                    <>
                        <ListItem
                            button
                            onClick={() => handleChange('account')}
                            id="sidebar_myaccount"
                        >
                            <div
                                className={`d-flex justify-content-between align-items-center w-100 test ${
                                    expanded === 'account' && 'activeDropdown'
                                }`}
                            >
                                <span className="d-flex">
                                    <span className="material-icons">person</span>
                                    {'My Account'}
                                </span>
                                {expanded === 'account' ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </ListItem>

                        <Collapse in={expanded === 'account'} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/account/profile"
                                        id="sidebar_profile"
                                    >
                                        {'Profile'}
                                    </NavLink>
                                </ListItem>
                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/account/change"
                                        id="sidebar_changepassword"
                                    >
                                        {'Change Password'}
                                    </NavLink>
                                </ListItem>
                                {user?.admin ? null : (
                                    <>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/account/notifications"
                                                id="sidebar_mynotification"
                                            >
                                                {'My Notifications'}
                                            </NavLink>
                                        </ListItem>
                                    </>
                                )}
                            </List>
                        </Collapse>
                    </>
                ) : null}
                {user?.admin ? (
                    <>
                        <ListItem button onClick={() => handleChange('seller/auctions')}>
                            <div
                                className={`d-flex justify-content-between align-items-center w-100 test ${
                                    expanded === 'seller/auctions' && 'activeDropdown'
                                }`}
                            >
                                <span className="d-flex" id="sidebar_myauctions">
                                    <span className="material-icons">gavel</span>
                                    My Auctions
                                </span>
                                {expanded === 'seller/auctions' ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </ListItem>

                        <Collapse in={expanded === 'seller/auctions'} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/auctions/upcoming"
                                        id="sidebar_upcoming"
                                    >
                                        Upcoming
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/auctions/open"
                                        id="sidebar_active"
                                    >
                                        Active
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/auctions/closed"
                                        id="sidebar_sold"
                                    >
                                        Sold
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/auctions/draft"
                                        id="sidebar_draft"
                                    >
                                        Draft
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem
                            button
                            onClick={() => handleChange('seller/lots')}
                            id="sidebar_mylots"
                        >
                            <div
                                className={`d-flex justify-content-between align-items-center w-100 test ${
                                    expanded === 'seller/lots' && 'activeDropdown'
                                }`}
                            >
                                <span className="d-flex">
                                    <span className="material-icons">gavel</span>
                                    My Lots
                                </span>
                                {expanded === 'seller/lots' ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </ListItem>

                        <Collapse in={expanded === 'seller/lots'} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/lots/open"
                                        id="sidebar_active_lots"
                                    >
                                        Active
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/lots/sold"
                                        id="sidebar_sold_lots"
                                    >
                                        Sold
                                    </NavLink>
                                </ListItem>
                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/lots/unsold"
                                        id="sidebar_unsold_lots"
                                    >
                                        Unsold
                                    </NavLink>
                                </ListItem>
                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/lots/draft"
                                        id="sidebar_draft_lots"
                                    >
                                        Draft
                                    </NavLink>
                                </ListItem>
                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/lots/relisted"
                                        id="sidebar_relisted_lots"
                                    >
                                        Relisted
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Collapse>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/seller/templates">
                                <span className="material-icons">table_chart</span>
                                Auction Templates
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/seller/product/templates">
                                <span className="material-icons">table_chart</span>
                                Listing Templates
                            </NavLink>
                        </ListItem>

                        {/* <ListItem button>
                            <NavLink activeClassName="active" to="/seller/organization">
                                <span className="material-icons">corporate_fare</span>
                                Organization
                            </NavLink>
                        </ListItem> */}

                        <ListItem button>
                            <NavLink activeClassName="active" to="/seller/reports">
                                <span className="material-icons">description</span>
                                Reports
                            </NavLink>
                        </ListItem>

                        {/* <ListItem button onClick={() => handleChange('payments')}>
                            <div
                                className={`d-flex justify-content-between align-items-center w-100 test ${
                                    expanded === 'payments' && 'activeDropdown'
                                }`}
                            >
                                <span className="d-flex">
                                    <span className="material-icons">credit_card</span>
                                    My Payments
                                </span>
                                {expanded === 'payments' ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </ListItem> */}

                        <Collapse in={expanded === 'payments'} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {/* <ListItem button>
                                    <NavLink activeClassName="active" to="/payments/transactions">
                                        Transactions
                                    </NavLink>
                                </ListItem> */}

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/payments/saved_cards"
                                    >
                                        Card Details
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/payments/bank_details"
                                    >
                                        Bank Details
                                    </NavLink>
                                </ListItem>

                                {/* <ListItem button>
                                    <NavLink activeClassName="active" to="/seller/subscription">
                                        My Subscription
                                    </NavLink>
                                </ListItem> */}
                            </List>
                        </Collapse>
                    </>
                ) : (
                    <>
                        {!noPendingInvoices ? (
                            <>
                                {' '}
                                <ListItem
                                    button
                                    onClick={() => handleChange('auctions')}
                                    id="sidebar_mybids"
                                >
                                    <div
                                        className={`d-flex justify-content-between align-items-center w-100 test ${
                                            expanded === 'auctions' && 'activeDropdown'
                                        }`}
                                    >
                                        <span className="d-flex">
                                            <span className="material-icons">gavel</span>
                                            {'My Bids'}
                                        </span>
                                        {expanded === 'auctions' ? <ExpandLess /> : <ExpandMore />}
                                    </div>
                                </ListItem>
                                <Collapse in={expanded === 'auctions'} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/auctions/all"
                                                id="sidebar_all_bids"
                                            >
                                                {'All'}
                                            </NavLink>
                                        </ListItem>

                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/auctions/winning"
                                                id="sidebar_winning_bids"
                                            >
                                                {'Winning'}
                                            </NavLink>
                                        </ListItem>

                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/auctions/outbid"
                                                id="sidebar_outbid_bids"
                                            >
                                                {'Losing'}
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/auctions/won"
                                                id="sidebar_won_bids"
                                            >
                                                {'Won'}
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/auctions/lost"
                                                id="sidebar_won_bids"
                                            >
                                                {'Lost'}
                                            </NavLink>
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </>
                        ) : null}
                        {!noPendingInvoices ? (
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/watchlist/watchlist"
                                    id="sidebar_favourite"
                                >
                                    <span className="material-icons">favorite</span>
                                    {'My Watchlist'}
                                </NavLink>
                            </ListItem>
                        ) : null}

                        <ListItem
                            button
                            onClick={() => handleChange('payments')}
                            id="sidebar_mypayments"
                        >
                            <div
                                className={`d-flex justify-content-between align-items-center w-100 test ${
                                    expanded === 'payments' && 'activeDropdown'
                                }`}
                            >
                                <span className="d-flex">
                                    <span className="material-icons">credit_card</span>
                                    My Payments
                                </span>
                                {expanded === 'payments' ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </ListItem>

                        <Collapse in={expanded === 'payments'} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {/* <ListItem button>
                                    <NavLink activeClassName="active" to="/payments/transactions">
                                        Transactions
                                    </NavLink>
                                </ListItem> */}

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/payments/saved_cards"
                                        id="sidebar_carddetails"
                                    >
                                        Card Details
                                    </NavLink>
                                </ListItem>

                                {/* <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/payments/bank_details"
                                        id="sidebar_bankdetails"
                                    >
                                        Bank Details
                                    </NavLink>
                                </ListItem> */}

                                {/* <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/payments/subscription"
                                        id="sidebar_mysubscrition"
                                    >
                                        My Subscription
                                    </NavLink>
                                </ListItem> */}
                            </List>
                        </Collapse>

                        {/* <ListItem button>
                            <NavLink activeClassName="active" to="/wallet" id="sidebar_mywallet">
                                <span className="material-icons">account_balance_wallet</span>
                                My Wallet
                            </NavLink>
                        </ListItem> */}
                        {!noPendingInvoices ? (
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/expired_auction"
                                    id="sidebar_mywallet"
                                >
                                    <span className="material-icons">gavel</span>
                                    Expired Auctions
                                </NavLink>
                            </ListItem>
                        ) : null}

                        <ListItem
                            button
                            onClick={() => handleChange('invoices')}
                            id="sidebar_myinvoices"
                        >
                            <div
                                className={`d-flex justify-content-between align-items-center w-100 test ${
                                    expanded === 'invoices' && 'activeDropdown'
                                }`}
                            >
                                <span className="d-flex">
                                    <span className="material-icons">receipt</span>
                                    My Receipts
                                </span>
                                {expanded === 'invoices' ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </ListItem>

                        <Collapse in={expanded === 'invoices'} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {!noPendingInvoices ? (
                                    <>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/invoices/all"
                                                id="sidebar_allinvoices"
                                            >
                                                All
                                            </NavLink>
                                        </ListItem>

                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/invoices/paid"
                                                id="sidebar_paid"
                                            >
                                                Paid
                                            </NavLink>
                                        </ListItem>
                                    </>
                                ) : null}

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/invoices/partial"
                                        id="sidebar_unpaid"
                                    >
                                        Unpaid
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Collapse>

                        <Collapse in={expanded === 'schedule'} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/schedule/pending"
                                        id="sidebar_allinvoices"
                                    >
                                        Pending
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/schedule/completed"
                                        id="sidebar_paid"
                                    >
                                        Completed
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Collapse>

                        {/* <ListItem button>
                            <NavLink activeClassName="active" to="/deposits/bid_deposit">
                                <span className="material-icons">how_to_vote</span> {'Deposits'}
                            </NavLink>
                        </ListItem> */}

                        {!noPendingInvoices ? (
                            <>
                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/user/credits"
                                        id="sidear_savedsearches"
                                    >
                                        <span className="material-icons">local_offer</span> My
                                        Credits/Coupon
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/return/all"
                                        id="sidear_savedsearches"
                                    >
                                        <span className="material-icons">assignment_return</span>{' '}
                                        Refunds
                                    </NavLink>
                                </ListItem>

                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/saved_search"
                                        id="sidear_savedsearches"
                                    >
                                        <span className="material-icons">bookmark</span> My Saved
                                        Searches
                                    </NavLink>
                                </ListItem>
                            </>
                        ) : null}
                    </>
                )}
            </ul>
        </div>
    )
}

export default SideNav
